import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const notFound = () => {
  return (
    <div className="notFound">
      <div className="spaceman-x">
        <StaticImage
          alt="Spaceman Agent 404"
          src="../images/spaceman.png"
          className="spaceman-y"
          placeholder="tracedSVG"
        />
      </div>
      <div className="notFound_page">
        <p className="notFound_page--title">404</p>
        <p>Whoops, something gone wrong!</p>
        <span>
          Not to worry, you can always go back to{" "}
          <a href="/" className="backlink">
            {" "}
            homepage
          </a>
          .
        </span>
      </div>
    </div>
  );
};

export default notFound;
